import BaseReport from '@/store/modules/BaseReport'

export default class Workers extends BaseReport {
    constructor() {
        const reportUrl = '/backend/workers/'
        super(reportUrl)

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.save = async function (ctx, data) {
            let url, method
            if (data.id) {
                url = `${reportUrl}${data.id}/`
                method = 'put'
            } else {
                url = reportUrl
                method = 'post'
            }

            return await BaseReport.makeRequest({ method, url, data })
        }

        this.actions.delete = async function (ctx, id) {
            let url = `${reportUrl}${id}/`
            let method = 'delete'
            return await BaseReport.makeRequest({ method, url })
        }

        /**
         * принудительная синхронизация
         */
        this.actions.sync = async function (ctx, data) {
            const url = '/backend/sync/sync_workers/'
            const method = 'post'
            return await BaseReport.makeRequest({ method, url, data })
        }
    }
}
