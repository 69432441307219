import store from '@/store'
import {ElMessage} from "element-plus";


export default class BaseReport {
    constructor (reportUrl) {
        const report = this

        this.namespaced = true

        this.state = {
            form: {
                schemaSearch: [],
                schemaEdit: [],
            },
            results: {
                defaultSort: undefined,
                fields: [],
            },
        }

        this.getters = {
            formSchemaSearch(state) {
                return state.form.schemaSearch
            },
            formSchemaEdit(state) {
                return state.form.schemaEdit
            },
            resultFields(state) {
                return state.results.fields.filter((f) => f.visible)
            },
            defaultSort(state) {
                let prop = state.results.defaultSort
                let order = 'ascending'
                if (prop && prop[0] === '-') {
                    prop = prop.slice(1)
                    order = 'descending'
                }
                return { prop, order }
            },
        }

        this.actions = {
            async loadMeta(ctx, data) {
                const params = report.getParams(data)

                let response = undefined
                let errorResponse = undefined
                try {
                    response = await store.$axios.options(reportUrl, { params })
                } catch (error) {
                    errorResponse = error.response
                }

                if (errorResponse)
                    ElMessage.error(`Ошибка сервера при обращении по адресу "${reportUrl}"`)
                else {
                    const schemaSearch = Object.values(response.data.actions.GET)
                    let schemaEdit = {}
                    if (response.data.actions.POST)
                        schemaEdit = Object.values(response.data.actions.POST)
                    ctx.commit('setFormMeta', {schemaSearch, schemaEdit})
                    ctx.commit('setResultMeta', {
                        defaultSort: response.data.result.default_sort,
                        fields: response.data.result.fields,
                    })
                }
            },
            async load(ctx, data) {
                const params = report.getParams(data)

                // отправка данных на сервер
                return await BaseReport.makeRequest({
                    method: 'get',
                    url: reportUrl,
                    params: params,
                })
            },
        }

        this.mutations = {
            setFormMeta(state, schema) {
                state.form = schema
            },
            setResultMeta(state, { defaultSort, fields }) {
                state.results.defaultSort = defaultSort
                state.results.fields = fields
            },
        }
    }

    static async makeRequest({method = 'get', url, params, data}) {
        let response
        try {
            response = await store.$axios({method, url, params, data})
        } catch (error) {
            response = error.response
        }
        let result = response.data
        let isSuccess = response.status < 300

        // отчет сформировался
        if (isSuccess)
            return { isSuccess, result }

        // ошибки валидации
        if (response.status === 400)
            return { isSuccess, result }

        // сервер сломался
        if (response.status === 500)
            return {
                isSuccess,
                result: {
                    errors: {
                        general: ['Ошибка сервера'],
                    },
                },
            }

        return {
            isSuccess,
            result: {
                errors: {
                    general: ['Неизвестная ошибка'],
                },
            },
        }
    }

    getParams(data) {
        const params = {}
        if (!data)
            return params
            
        // подготовим данные для отправки на сервер
        for (const [key, value] of Object.entries(data)) {
            let schemaField = this.state.form.schemaSearch.filter((f) => f.name === key)[0]
            if (schemaField && schemaField.type === 'date' && value && typeof value !== 'string')
                params[key] = value.toLocaleDateString()
            else
                params[key] = value
        }
        
        return params
    }
}

