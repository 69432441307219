import BaseReport from '@/store/modules/BaseReport'

export default class ContLists extends BaseReport {
    constructor() {
        const reportUrl = '/backend/cont_lists/'
        super(reportUrl)

        this.actions.loadWorkers = async (ctx, id) => {
            const url = `${ reportUrl }${ id }/workers`
            const method = 'get'
            return await BaseReport.makeRequest({ method, url })
        }

        this.actions.getOne = async function (ctx, id) {
            const url = `${reportUrl}${id}/`
            const method = 'get'
            return await BaseReport.makeRequest({method, url})
        }
    }
}
